import React from "react";
import {
  FooterWrapper,
  FooterContainer,
  FooterTop,
  FooterItem,
  FooterTitle,
  FooterListItem,
  FooterIcon,
  FooterCopyright,
  FooterSocialLinks,
  SocialIcon,
} from "./FooterElements";
import { footerLinks } from "./data";
import Typography from "../Typography/Typography";
import useSiteSettings from "../../hooks/useSiteSettings";
import {
  FaMapMarkerAlt,
  FaFacebookF,
  FaInstagramSquare,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { IoIosCall, IoMdMail } from "react-icons/io";
import { SiTiktok } from "react-icons/si";
import googlePlayImage from "../../assets/googlePlay.png";
import appleStoreImage from "../../assets/appleStore.png";
import TwitterXLogo from "../../assets/twitter-X.png";
// import { useHistory } from "react-router-dom";

export default () => {
  const { data } = useSiteSettings();
  // const history = useHistory();

  let contactList;
  let footerSocialLinks;
  let copyrightText;
  let twitterURL;
     

  if (data) {
    const {
      address_primary,
      address_secondary,
      contact_primary,
      // contact_secondary,
      email,
      facebook,
      instagram,
      linkedin,
      twitter,
      youtube,
      footer_content,
    } = data[0];

	twitterURL = twitter;
    copyrightText = footer_content;
    contactList = [
      {
        id: 4.1,
        name: address_primary,
        name2: address_secondary,
        icon: <FaMapMarkerAlt />,
        href: null,
      },
      {
        id: 4.2,
        name: contact_primary,
        icon: <IoIosCall />,
        href: `tel:${contact_primary}`,
      },
      {
        id: 4.3,
        name: email,
        icon: <IoMdMail />,
        href: `mailto:${email}`,
      },
    ];
    footerSocialLinks = [
      {
        id: 1,
        icon: <FaFacebookF />,
        url: facebook,
      },
      {
        id: 2,
        icon: <FaInstagramSquare />,
        url: instagram,
      },
      {
        id: 3,
        icon: <FaLinkedinIn />,
        url: linkedin,
      },
      
      {
        id: 5,
        icon: <FaYoutube />,
        url: youtube,
      },
      {
        id: 6,
        icon: <SiTiktok />,
        url: "https://www.tiktok.com/@bighoundusa",
      },
    ];
  }

  return (
    <FooterWrapper style={{background:'#033a72',color:'white'}}>
      <FooterContainer>
        <FooterTop>
          {footerLinks.map(({ id, title, list }) => (
            <FooterItem key={id}>
              <FooterTitle>
                <Typography h3 color="#fff">
                  {title}
                </Typography>
              </FooterTitle>
              {list.map(({ id, name, icon, to, target }) => (
                // isReload
                <FooterListItem
                  key={id}
                  target={target ? "_blank" : ""}
                  to={to}
                  // onClick={() => {
                  //   history.push(to);
                  //   if (isReload) {
                  //     location.reload();
                  //   }
                  // }}
                >
                  {icon && <FooterIcon>{icon}</FooterIcon>}
                  <Typography body2 color="white">
                    {name}
                  </Typography>
                </FooterListItem>
              ))}
            </FooterItem>
          ))}
          <FooterItem>
            <FooterTitle>
              <Typography h3 color="#fff">
                Contact
              </Typography>
            </FooterTitle>
            {contactList?.map(({ id, name, name2, icon, href }) => (
              <div
                key={id}
                style={{
                  display: "flex",
                  padding: "5px 0",
                  alignItems: "center",
                }}
              >
                {icon && <FooterIcon>{icon}</FooterIcon>}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <a
                    href={href}
                    style={{ color: "white", textDecoration: "none" }}
                  >
                     {name}
                  </a>
                  {name2 && (
                    <Typography body1 color="white">
                     {name2}
                    </Typography>
                  )}
                </div>
              </div>
            ))}
          </FooterItem>
        </FooterTop>
        <FooterCopyright>
          <Typography body2 color="#CDCDCD">
            <p style={{color:'white'}}>&copy; 2025 CRE Guide. All rights reserved. </p>
            <div dangerouslySetInnerHTML={{ __html: copyrightText }} />
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }} className="footerbottom">
            <a
              href="https://play.google.com/store/apps/details?id=com.bighound.mobile"
              target="_blank"
            >
              
              <img
              className="footer-icon"
                src={googlePlayImage}
                style={{ height: 40, cursor: "pointer", marginRight: 10 }}
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/big-hound/id1548890973"
              target="_blank"
            >
              <img
               className="footer-icon"
                src={appleStoreImage}
                style={{ height: 40, cursor: "pointer" }}
              />
            </a>
			
            <FooterSocialLinks>
			<a
              href={twitterURL}
              target="_blank"
            >
              <img
              className="socialimg"
                src={TwitterXLogo}
                style={{ height: 18, cursor: "pointer", marginLeft: 20, marginRight: -5 }}
              />
            </a>
              {footerSocialLinks?.map(({ id, icon, url }) => (
                <a
                  key={id}
                  href={url}
                  target="_blank"
                  onClick={() => {
                    if (!url) return null;
                    if (url.indexOf("http") > -1) {
                      window.open(url);
                    } else {
                      window.open(`http://${url}`);
                    }
                  }}
                >
                  <SocialIcon>{icon}</SocialIcon>
                </a>
              ))}
            </FooterSocialLinks>
          </div>
        </FooterCopyright>
      </FooterContainer>
    </FooterWrapper>
  );
};
