import React from "react";
import {
  SidebarContainer,
  CloseIcon,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
} from "./SidebarElements";
import { FaTimes, FaSignInAlt } from "react-icons/fa";
import Button from "../Button/Button";
import { AppContext } from "../../AppContext";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";

export default ({ isSidebarOpen, toggleSidebar }) => {
  const { currentUser, setCurrentUser } = React.useContext(AppContext);
  const history = useHistory();

  const mystyle = {
    color: "white",
    backgroundColor: "#033A72",
   
  };

  return (
    <SidebarContainer isSidebarOpen={isSidebarOpen}>
      <CloseIcon onClick={toggleSidebar}>
        <FaTimes />
      </CloseIcon>
      <SidebarMenu>
        {currentUser && (
          <SidebarLink onClick={toggleSidebar} to="/profile/basic">
            Profile
          </SidebarLink>
        )}
        <SidebarLink onClick={toggleSidebar} to="/">
          Home
        </SidebarLink>
        <SidebarLink onClick={toggleSidebar} to="/communityBoard">
          Community Board
        </SidebarLink>
        <SidebarLink onClick={toggleSidebar} to="/aboutUs">
          About Us
        </SidebarLink>
        <SidebarLink onClick={toggleSidebar} to="/market-insights">
          Market Insights
        </SidebarLink>
        <SidebarLink onClick={toggleSidebar} to="/news">
          News & Articles
        </SidebarLink>
        <SidebarLink onClick={toggleSidebar} to="/contactUs">
          Contact Us
        </SidebarLink>
        {currentUser && (
          <SidebarLink
            to=""
            onClick={() => {
              toggleSidebar();
              localStorage.clear();
              setCurrentUser(null);
              firebase.auth().signOut();
              history.push("/");
            }}
          >
            Logout
          </SidebarLink>
        )}
      </SidebarMenu>
      {!currentUser && (
        <SideBtnWrap>
          <SidebarLink to="/signIn" onClick={toggleSidebar}>
            Sign In
          </SidebarLink>
          <SidebarLink to="/signUp" onClick={toggleSidebar}>
            <Button icon={<FaSignInAlt />} style={mystyle}>Sign Up</Button>
          </SidebarLink>
        </SideBtnWrap>
      )}
    </SidebarContainer>
  );
};
