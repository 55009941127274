import styled from "styled-components";
import footerImage from "../../assets/footerImage.png";
import { MAX_WIDTH } from "../../constants/layout";
import { Link } from "react-router-dom";

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-image: url(${footerImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 10px;
`;

export const FooterContainer = styled.div`
  max-width: ${MAX_WIDTH};
  width: 100%;
 
`;
export const FooterTop = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FooterSocialLinksContainer = styled.div``;

export const FooterItem = styled.div`
  display: flex;
  flex: 25%;
  flex-direction: column;
  padding: 30px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      @media only screen and (max-width: 767px) {
      padding:15px 0px;
      padding-top:10px;
      h3{
      font-size:13px}
      span{
      font-size:12px !important;}

    }
`;

export const FooterTitle = styled.div`
  padding: 10px 0;
`;

export const FooterListItem = styled(Link)`
  display: flex;
  padding: 4px 0;
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
    text-decoration-color: #fff;
  }
`;

export const FooterIcon = styled.div`
  color: #fff;
  margin-right: 10px;
`;

export const FooterCopyright = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;

    @media only screen and (max-width: 767px) {
    display:block;
    padding-bottom:5px;
      .footer-icon{
      height:33px !important;
      }
      .footerbottom{
      display:block !important;
      margin:5px 0px;
      }
      .socialimg{
      margin-left:0px !important;}
    
    }
  
     
    
`;

export const FooterSocialLinks = styled.div`
  display: flex;
  
`;

export const SocialIcon = styled.span`
  color: #fff;
  margin-left: 15px;
  cursor: pointer;
`;


