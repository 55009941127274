import styled from "styled-components";
import colors from "../../../constants/colors";

export const SocialTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 40px;
`;

export const CategoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  flex-flow: wrap;
  padding: 0 10px;
   
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
  width: 16%;
  background-color: #fff;
  color: #494949;
  font-size: 0.8rem;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;

  .category-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }

  :hover {
    background-color: ${colors.primary};
    color: #fff;

    .category-icon path {
      fill: #fff;
    }
  }

  @media screen and (max-width: 900px) {
    width: 49%;
    margin-bottom: 10px;
  }
    @media only screen and (max-width: 767px) {
      span{
      font-size:14px !important;
      }
    }
`;

export const CategoryText = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.24px;
`;
