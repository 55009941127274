import styled from "styled-components";
import heroImage1 from "../../../assets/heroImage1.png";
import colors from "../../../constants/colors";
import { MAX_WIDTH, sm } from "../../../constants/layout";

export const HeroSection = styled.div`
  display: flex;
  justify-content: center;
  background: url(${heroImage1});
  height: 440px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 10px;
  object-fit: contain;

  @media screen and (max-width: ${sm}) {
    height: 380px;
    h1{
         font-size: 20px;
        max-width: 100% !important;
        margin-bottom:20px;
       
      }
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: ${MAX_WIDTH};
  padding: 55px 0;
  @media screen and (max-width: ${sm}) {
  justify-content: inherit;
   
  }
`;

export const HeroDescription = styled.div`
  max-width: 600px;
  margin: 10px 0;
`;

export const HeroSearchSection = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
  padding: 30px;
  border-radius: 4px;
  max-width: 800px;

  @media screen and (max-width: ${sm}) {
    padding: 0px;
  }
`;

export const SearchCategory = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CategoryBtn = styled.button`
  background-color: ${(props) => (props.selected ? colors.primary : "white")};
  color: ${(props) => (props.selected ? "#fff" : "black")};
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-top-left-radius: ${(props) => (props.borderTopLeft ? 4 : 0)}px;
  border-top-right-radius: ${(props) => (props.borderTopRight ? 4 : 0)}px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0px;
  @media screen and (max-width: ${sm}) {
    width: 33.33%;
    padding: 10px 10px;
    font-size:11px;
  }
`;

export const SearchInputSection = styled.form`
  display: flex;
  @media screen and (max-width: ${sm}) {
    flex-direction: column;
  }
`;

export const SearchInput = styled.input`
  width: 70%;
  height: 56px;
  padding: 0 20px;
  border: none;
  outline: none;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0px;

  ::placeholder {
    color: #b0b0b0;
  }
  @media screen and (max-width: ${sm}) {
    width: 100%;
    border-bottom-left-radius: 0px;
    margin-bottom:15px;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  width: 30%;
  height: 56px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary};
  outline: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(85%);
  }

  @media screen and (max-width: ${sm}) {
    width: 100%;
  }
`;

export const SearchIcon = styled.span`
  display: flex;
  margin-right: 12px;
  font-size: 22px;
`;

